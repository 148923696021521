<template>
    <div>
      <v-row>
        <v-col cols="12">
          <EmailForm @email-created="fetchEmails" />
        </v-col>
        <v-col cols="2">
          <v-btn v-if="!emailsFiltered" @click="fetchSentEmails">Show Unsent Addresses</v-btn>
          <v-btn v-if="emailsFiltered" @click="fetchEmails">Show All Addresses</v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn @click="fetchSentEmails">Send To Unsent Addresses</v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table :items="emails" :headers="headers">
            <template v-slot:[`item.action`]="{ item }">
              <v-btn @click="sendEmail(item.email)" flat="true">Send Email</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
import EmailForm from './EmailForm.vue';
  
  export default {
    components: {
    EmailForm,
  },
    data() {
      return {
        emails: [],
        search: '',
        headers: [
          { title: 'First Name', value: 'firstName' },
          { title: 'Last Name', value: 'lastName' },
          { title: 'Email', value: 'email' },
          { title: 'Actions', value: 'action', sortable: false }
        ],
        emailsFiltered: false
      };
    },
    computed: {
    filteredEmails() {
      return this.emails.filter(email => {
        const fullName = `${email.firstName} ${email.lastName}`.toLowerCase();
        return (
          fullName.includes(this.search.toLowerCase()) ||
          email.email.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
    created() {
      this.fetchEmails();
    },
    methods: {
      async fetchEmails() {
        console.log('fetching emails');
        const response = await axios.get('https://cmcwebdevelopment.com/copilot/api/emails');
        this.emails = response.data;
        this.emailsFiltered = false;
      },
      async deleteTask(id) {
        await axios.delete(`https://cmcwebdevelopment.com/copilot/api/emails/${id}`);
        this.fetchEmails();
      },
      async toggleComplete(email) {
        email.completed = !email.completed;
        await axios.put(`https://cmcwebdevelopment.com/copilot/api/emails/${email._id}`, email);
        this.fetchEmails();
      },
      async sendEmail(email) {
        try {
          const response = await axios.post('https://cmcwebdevelopment.com/copilot/api/send-email', { email });
          console.log('Email sent:', response.data);
        } catch (error) {
          console.error('Failed to send email:', error);
        }
      },
      async fetchSentEmails() {
          console.log('fetching emails');
          const response = await axios.get('https://cmcwebdevelopment.com/copilot/api/sent-emails');
          this.emails = this.emails.filter(email => !response.data.some(sentEmail => sentEmail.sentTo === email.email));
          this.emailsFiltered = !this.emailsFiltered;
      },
    },
  };
  </script>
  ./EmailForm.vue