<template>
    <div>
      <v-row>
        <v-col cols="12">
          <v-btn v-if="!showClickedLinksOnly" @click="showClickedLinks">Show Unclicked Links</v-btn>
          <v-btn v-if="showClickedLinksOnly" @click="fetchSentEmails">Show All Emails</v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table :items="emails" :headers="headers">
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </template>
<script>
import axios from 'axios';
    export default {
        data() {
            return {
                emails: [],
                search: '',
                headers: [
                    { title: 'Sent To', value: 'sentTo' },
                    { title: 'Link Clicked', value: 'linkClicked' },
                    { title: 'Responded To', value: 'respondedTo'},
                    { title: 'Sent Date', value: 'sendDate' }
                ],
                showClickedLinksOnly: false
            };
        },
        created() {
        this.fetchSentEmails();
        },
        methods: {
            async fetchSentEmails() {
                console.log('fetching emails');
                const response = await axios.get('https://cmcwebdevelopment.com/copilot/api/sent-emails');
                this.emails = response.data;
                this.showClickedLinksOnly = false;
              },
              showClickedLinks() {
                this.emails = this.emails.filter(email => email.linkClicked === true);
                this.showClickedLinksOnly = true;
              },
        }
    }
</script>

<style lang="scss" scoped>

</style>