<template>
    <div>
      <h2>Upload Resume PDF</h2>
      <v-form v-model="valid" @submit.prevent="uploadResume">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-file-input
                v-model="file"
                label="Select PDF"
                accept=".pdf"
                required
                @change="handleFileChange"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-btn class="mt-2" @click="uploadResume">Upload</v-btn>
        </v-container>
      </v-form>
      <div v-if="pdfUrl">
      <h3>Uploaded Resume</h3>
      <div style="height: 100vh;width: 100%;">
        <object :data="pdfUrlWithParams" type="application/pdf" style="width: 100%; height: 100%;"></object>
      </div>
      <!-- <iframe :src="pdfUrl" width="100%" height="1000px"></iframe> -->
    </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        valid: false,
        file: null,
        base64PDF: '',
        pdfUrl: null,
      };
    },
    created() {
      this.fetchResumes();
    },
    computed: {
    pdfUrlWithParams() {
      // Add your additional parameters here
      const additionalParams = '#thumbnails=0&view=FitBV&toolbar=0';
      return this.pdfUrl ? `${this.pdfUrl}${additionalParams}` : null;
    }
  },
    methods: {
      handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.base64PDF = e.target.result.split(',')[1]; // Extract base64 string
          };
          reader.readAsDataURL(file);
        }
      },
      async uploadResume() {
        if (this.base64PDF) {
          try {
            const response = await axios.post('https://cmcwebdevelopment.com/copilot/api/resumes', {
              base64PDF: this.base64PDF,
            });
            console.log('Resume uploaded:', response.data);
          } catch (error) {
            console.error('Error uploading resume:', error);
          }
        }
      },
      decodeBase64ToPdf(base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/pdf' });
      this.pdfUrl = URL.createObjectURL(blob);
    },
      async fetchResumes() {
        console.log('fetching resumes');
        const response = await axios.get('https://cmcwebdevelopment.com/copilot/api/resumes');
        this.resumes = response.data;
        this.decodeBase64ToPdf(this.resumes[0].base64PDF);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any necessary styles here */
  </style>