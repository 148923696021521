<template>
  <v-container>
    <div>
      <h2>Add Email</h2>
    <v-form v-model="valid" @submit.prevent="createEmail">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="firstname"
            :counter="10"
            :rules="nameRules"
            label="First name"
            hide-details
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="lastname"
            :counter="10"
            :rules="nameRules"
            label="Last name"
            hide-details
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            hide-details
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn class="mt-2" @click="createEmail">Submit</v-btn>
    </v-form>
  </div>
</v-container>
  </template>
  
  <script>
  import axios from 'axios';

  
  export default {
    data() {
      return {
        title: '',
        description: '',
        valid: false,
      firstname: '',
      lastname: '',
      nameRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        },
        value => {
          if (value?.length <= 10) return true

          return 'Name must be less than 10 characters.'
        },
      ],
      email: '',
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is requred.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
      };
    },
    methods: {
      async createEmail() {
        await axios.post('https://cmcwebdevelopment.com/copilot/api/emails', {
          firstName: this.firstname,
          lastName: this.lastname,
          email: this.email,
        });
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        console.log('email created');
        this.$emit('email-created');
      },
    },
  };
  </script>